@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
.loader {
  width: 100%;
  height: 100%;
  background: #f7f8fd;
  opacity: 1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1010;
}
.spinner {
  width: 80px;
  height: 80px;
  margin: 0px auto;
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 2px solid #012d51;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #012d51 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav {
  height: 75px;
  background: #f7f8fd;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 1px 7px rgba(1, 45, 81, 0.2);
}
.nav .brand {
  position: absolute;
  left: 20px;
  color: #fff !important;
  top: 12px;
  width: 200px;
  height: 50px;
  display: block;
  letter-spacing: 2px;
  font-weight: 700;
  text-indent: -9999px;

  background: url("/assets/logo.png") no-repeat center center;
  background-size: contain;
}
.nav .user-menu {
  position: absolute;
  right: 20px;
  top: 12px;
  color: #012d51 !important;
  font-size: 30px;
}

.login-form {
  padding: 140px 50px 50px 50px;
  max-width: 500px;
  margin: 0 auto;
}
.login-form h1 {
  text-align: center;
}
@media (max-width: 600px) {
  .login-form {
    max-width: 80%;
  }
}
.ui-field {
  margin: 0 0 10px 0;
}
.footer {
  text-align: center;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}

.main-menu {
  background: #f7f8fd;
  width: 280px;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 800px) {
  .main-menu {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 75px;
    left: 0;
  }
}
.main-menu .account-name {
  color: #012d51;
}
.main-menu .content {
  padding: 100px 20px 20px 20px;
}
.main-menu-header {
  margin-bottom: 30px;
}
.main-menu .content p {
  font-size: 16px;
}
.main-menu ul {
  padding: 0;
}
.main-menu ul li {
  list-style-type: none;
}
.main-menu ul li a {
  display: block;
  position: relative;
  color: #666 !important;
  font-size: 13px;
  font-weight: 700;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 10px 30px 10px 30px;
  font-family: "Open Sans Condensed", sans-serif;
}
.main-menu ul li a .anticon {
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 10px;
}
a[disabled] {
  color: #ddd !important;
}
.main-menu .primary-menu {
  margin-bottom: 100px;
}
.child-menu li a {
  font-weight: 700 !important;
  padding: 10px 30px 10px 50px !important;
}
.account-select {
  margin: 0 0 40px 0;
}

.ant-table td {
  white-space: nowrap;
  font-size: 12px;
}

.user-permission {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.user-permission:last-child {
  border-bottom: none;
}

.view-loading {
  color: #00528c;
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 0px;
}


.align-right {
  text-align: right;
}

.user-permission {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.user-permission:last-child {
  border-bottom: none;
}

body {
  background: #fff !important;
}
.App {
  font-family: "Roboto", sans-serif;
  color: #515666 !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
  color: #012d51 !important;
  font-weight: 500 !important;
}
p {
  color: #8b90a0 !important;
}
.ant-btn {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300 !important;
  color: #555 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  text-transform: uppercase;
  font-size: 12px !important;
  letter-spacing: 1px;
  text-shadow: 1px 1px #888 !important;
}
.ant-btn-primary {
  background: #04c784 !important;
  border: none !important;
  color: #fff !important;
}
a {
  color: #04c784 !important;
}
label {
  color: #8b90a0 !important;
}
.page {
  margin-left: 340px;
  padding-top: 120px;
  margin-right: 60px;
}
@media (max-width: 800px) {
  .page {
    padding: 20px;
    margin-left: 0;
    padding-top: 150px;
    margin-right: 0;
  }
}
.page h2 {
  font-size: 30px;
}
.ant-modal-body {
  font-family: "Roboto", sans-serif;
  color: #515666 !important;
  font-size: 16px;
}

