@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

body {
  background: #fff !important;
}
.App {
  font-family: "Roboto", sans-serif;
  color: #515666 !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
  color: #012d51 !important;
  font-weight: 500 !important;
}
p {
  color: #8b90a0 !important;
}
.ant-btn {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300 !important;
  color: #555 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  text-transform: uppercase;
  font-size: 12px !important;
  letter-spacing: 1px;
  text-shadow: 1px 1px #888 !important;
}
.ant-btn-primary {
  background: #04c784 !important;
  border: none !important;
  color: #fff !important;
}
a {
  color: #04c784 !important;
}
label {
  color: #8b90a0 !important;
}
.page {
  margin-left: 340px;
  padding-top: 120px;
  margin-right: 60px;
}
@media (max-width: 800px) {
  .page {
    padding: 20px;
    margin-left: 0;
    padding-top: 150px;
    margin-right: 0;
  }
}
.page h2 {
  font-size: 30px;
}
.ant-modal-body {
  font-family: "Roboto", sans-serif;
  color: #515666 !important;
  font-size: 16px;
}
