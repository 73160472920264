.nav {
  height: 75px;
  background: #f7f8fd;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 1px 7px rgba(1, 45, 81, 0.2);
}
.nav .brand {
  position: absolute;
  left: 20px;
  color: #fff !important;
  top: 12px;
  width: 200px;
  height: 50px;
  display: block;
  letter-spacing: 2px;
  font-weight: 700;
  text-indent: -9999px;

  background: url("/assets/logo.png") no-repeat center center;
  background-size: contain;
}
.nav .user-menu {
  position: absolute;
  right: 20px;
  top: 12px;
  color: #012d51 !important;
  font-size: 30px;
}
