.login-form {
  padding: 140px 50px 50px 50px;
  max-width: 500px;
  margin: 0 auto;
}
.login-form h1 {
  text-align: center;
}
@media (max-width: 600px) {
  .login-form {
    max-width: 80%;
  }
}
.ui-field {
  margin: 0 0 10px 0;
}
.footer {
  text-align: center;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}
