.main-menu {
  background: #f7f8fd;
  width: 280px;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 800px) {
  .main-menu {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 75px;
    left: 0;
  }
}
.main-menu .account-name {
  color: #012d51;
}
.main-menu .content {
  padding: 100px 20px 20px 20px;
}
.main-menu-header {
  margin-bottom: 30px;
}
.main-menu .content p {
  font-size: 16px;
}
.main-menu ul {
  padding: 0;
}
.main-menu ul li {
  list-style-type: none;
}
.main-menu ul li a {
  display: block;
  position: relative;
  color: #666 !important;
  font-size: 13px;
  font-weight: 700;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 10px 30px 10px 30px;
  font-family: "Open Sans Condensed", sans-serif;
}
.main-menu ul li a .anticon {
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 10px;
}
a[disabled] {
  color: #ddd !important;
}
.main-menu .primary-menu {
  margin-bottom: 100px;
}
.child-menu li a {
  font-weight: 700 !important;
  padding: 10px 30px 10px 50px !important;
}
.account-select {
  margin: 0 0 40px 0;
}
